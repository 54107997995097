.FileTransfer {
  text-align: center;
}

.FileTransfer-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.FileTransfer-title {
  font-size: 1.5em;
}

.FileTransfer-intro {
  font-size: large;
}

@keyframes FileTransfer-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#download-from {
  text-align: left;
}

a.transferLink {
  color: inherit;
}

.timeInput {
  display: flex;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.24em;
  padding: 0.3em;
  align-items: center;
}

.timeInput.inline {
  display: inline-flex;
}

.ui.form .timeInput input,
.ui.form .timeInput select,
.timeInput input,
.timeInput select {
  border: 0px;
  padding: 0px;
  width: auto;
}

.ui.form .timeInput input:not([type]) {
  border: 0px;
  padding: 0px;
}

.ui.form .timeInput select,
.timeInput select {
  width: auto;
}

.ui.form .timeInput input,
.ui.form .timeInput input:not([type]),
.timeInput input {
  width: 2em;
  text-align: center;
}

.ui.form .timeInput input.millisecondsInput,
.timeInput input.millisecondsInput {
  width: 3em;
  text-align: center;
}

.ui.form .timeInput input:active,
.ui.form .timeInput select:focus,
.ui.form .timeInput select:active,
.timeInput input:active,
.timeInput select:focus,
.timeInput select:active {
  outline: none;
  background-color: lightgrey;
}

.timeInput input[type=number]::-webkit-inner-spin-button,
.timeInput input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timeInput input[type=number] {
  -moz-appearance:textfield;
}

span.unitDivider {
  font-weight: bold;
  font-family: sans-serif;
}

.ui.form span.unitDivider {
  font-size: 1.2em;
}

.dateTimeInput {
  display: flex;
}

.dateTimeInput.inline {
  flex-direction: column;
}

.dateTimeInput .react-datepicker-wrapper {
  flex-grow: 1;
}

.ui.form .fields.RequestFormSelectMultipleGroup {
  flex-wrap: wrap;
}

.ui.form .fields.RequestFormGroup {
  margin-bottom: 1em!important;
}

.ui.form .fields.grouprequired > label:after {
  display: inline-block;
  vertical-align: top;
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828;
}

#UploadControlsContainer input{
  overflow: hidden;
  word-wrap: none;
  text-overflow: ellipsis;
}

#uploadHeader {
  margin: 0.5em;
}

#UploadApp {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50ch, 1fr));
  grid-row-gap: 0.5em;
  overflow: hidden;
}

#FileTransferContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 0;
  padding: 0 0.5em 0.5em 0.5em;
}

#FileTransfers {
  flex-grow: 1;
  overflow-y: auto;
}

#UploadControlsContainer {
  padding: 0 0.5em 0.5em 0.5em;
  width: 100%;
}

#fileTransfersHeader {
  margin: 0.5em;
}

#transfersClearAll {
  flex-shrink: 0;
}

div.scheduleTableContainer {
  overflow-y: auto;
  margin: 0;
  margin-left: -3px;
  flex-grow: 1;
  flex-basis: 0;
}

tr.tableBlockLabel {
  font-weight: bold;
  background-color: #7fb3cc;
  font-size: 1.1em;
  //text-shadow: -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white, 1px 1px 0 white;
}

tr.tableBlockItem {
  background-color: #f1fbfd;
  font-size: 1.1em;
}

.ui.table tr.tableBlockLabel td {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.ui.table tr.tableBlockLabel > td > div.ui.checkbox {
  vertical-align: text-bottom;
}

.ui.table.celled tr.tableBlockItem > td:first-child {
  border-left: 4px solid #7fb3cc;
}

.ui.table.celled tr.tableBlockItem > td:last-child {
  border-right: 4px solid #7fb3cc;
}

tr.empty {
  background-color: #b8bbb9;
  font-size: 1em;
}

tr.tableBlockItem.empty {
  background-color: #a7b9bd;
}

tr.empty > td.scheduleItemLabel {
  color: #535050;
}

tr.isPlaying {
  font-weight: bold;
  color: darkgreen;
  outline: solid 1px #4bb64a;
}

tr.isPlaying > td.scheduleItemTime {
  background-color: #4bb64a;
  color: white;
}

span.startTimeLabel,
span.endTimeLabel,
span.scheduleItemDuration {
  white-space: nowrap;
}

tr.frontError > td.scheduleItemTime,
tr.rearError > td.scheduleItemTime {
  background-color: #d9002e;
  color: white;
}

tr.dneError > td.scheduleItemLabel {
  background-color: #d9002e;
  color: white;
}

tr.frontError span.endTimeLabel {
  font-weight: bold;
}

tr.rearError span.startTimeLabel {
  font-weight: bold;
}

tr.tableBlockLabel.error > td.blockTime {
  background-color: #d9002e;
  color: white;
  font-weight: bold;
}

tr.selected {
  background-color: #dfd;
}

tr.tableBlockLabel.selected {
  background-color: #5f5;
}

tr.tableBlockItem.selected {
  background-color: #9f9;
}

.ui.table.celled tr.tableBlockItem.blockSelected > td:first-child {
  border-left-color: #5f5;
}

.ui.table.celled tr.tableBlockItem.blockSelected > td:last-child {
  border-right-color: #5f5;
}

.ui.label > div.detail.scte35Detail {
  display: block;
  margin-left: 0;
}

.ui.buttons.attached.blockQuickButtons {
  z-index: auto;
}

/*
 * So the table REALLY annoyed me that it had 1 pixel of white space. This should eradicate this problem via the pits of hell.
 */
table.ui.celled.compact.sixteen.column.table {
  margin: 0;
}

.ui.table tr.error.tableBlockLabel {
  background-color: #ffd6d6 !important;
}

.ui.table tr.error.selected {
  background-color: #fcc !important;
}

.ui.table tr.error.tableBlockLabel.selected {
  background-color: #f99 !important;
}

div.rdt > div {
  flex-grow: 1;
}

.hasItems,
div.hasItems {
  background-color: #afa;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected.hasItems {
  background-color: #428bca;
  color: white;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div {
  height: 100%;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-wrapper.item.intervalOptionsCalendarButton > div > div:hover {
  cursor: pointer;
  background: rgba(0,0,0,.03);
  color: rgba(0,0,0,.95);
}

div.scheduleTimeSelector > div.rdt > div.rdtPicker > div.rdtMonths > table > thead {
  visibility: collapse;
}

.hasItems,
div.hasItems {
  background-color: #afa;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected.hasItems {
  background-color: #428bca;
  color: white;
}

div.scheduleReplicateTimeSelector > div.rdt > div.rdtPicker {
  position: static;
}

div.scheduleReplicateTimeSelector > div.rdt > div.rdtPicker > div.rdtMonths > table > thead {
  visibility: collapse;
}

div.react-datepicker__day--selected.noViewHighlight,
div.react-datepicker__day--keyboard-selected.noViewHighlight {
  background-color: inherit;
  color: black;
}

.isSelected,
div.isSelected,
div.isSelected:hover,
div.react-datepicker__day--selected.isSelected,
div.react-datepicker__day--selected.noViewHighlight.isSelected,
div.react-datepicker__day--keyboard-selected.noViewHighlight.isSelected {
  background-color: #afa;
  color: black;
}

.isBasis,
.isSelected.isBasis,
.react-datepicker__day.isBasis {
  color: white;
  background-color: #216ba5;
}

th.dow:hover {
  cursor: pointer;
  background-color: #eee;
}

.ui.item.menu a.item.hasItems {
  background-color: #9f9;
}

.ui.item.menu a.item.hasItems.active {
  background-color: #093;
  color: white;
}

.ui.menu .item.scheduleMenuDropdown::before {
  display: none;
}

.scheduleDefaultsPanel .ui.segment {
  padding: 0.5em;
}

.ui.grid.scheduleDefaultsPanel > .row > .column {
  padding: 2px;
}

.ui.container.scheduleDefaultsTextContainer {
  display: flex;
}

.scheduleDefaultsText {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex-grow: 1;
}

.scheduleDefaultsRemoveIcon:hover {
  cursor: pointer;
}

.createScheduleName,
.createScheduleType {
  margin: 0.5em 0;
}

.createScheduleButtons {
  text-align: right;
}

div#schedulePanel {
  display: flex;
  flex-direction: column;
  padding-left: 3px;
}

div.scheduleEditorButtonLabel {
  margin-top: 3px;
}

div.libraryTableBody .ui.table.striped tbody tr.highlighted {
  font-weight: bold;
  outline: solid 2px #315da9;
  background-color: #c8dfff;
}

.ui.cards > .card.highlighted {
  font-weight: bold;
  outline: solid 2px #315da9;
  background-color: #c8dfff;
}

.ui.cards > .ui.card.active {
  background: #e0e0e0;
}

.ui.cards > .ui.card.closed {
  border: solid 1px red;
}

div.libraryTablePanel {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

div.libraryTableBody {
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}

div.libraryTableBody > table {
  table-layout: fixed;
}

td.cellWordBreak {
  word-break: break-all;
}

td.cellNoOverflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

tbody.libraryTableHeaderSizer {
  visibility: collapse;
}

div.libraryTilesPanel {
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1em;
  flex-direction: column;
}

div.tileIcon {
  height: 10vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

div.tileIcon img.ui.image {
  max-height: 100%;
}

.tileHeader {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.container.tileTypeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

div.tileImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.ui.green.card > .content {
  outline: 2px #21ba45 solid;
}

/*
 * The search bar was tiny and annoying and puny, so we removed it's walls and made it wide. 
 * Now it's plainly visible and actually kinda good looking
 */

.ui.search.librarySearch {
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    margin-left: 1em;
}
.ui.icon.input {
    width: 100%;
}

div.libraryComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1px;
  padding-right: 1px;
  position: relative;
}

#searchbar-container {
  width: 100%;
}

div.libraryNavBar {
  display: flex;
  flex-wrap: wrap;
}

div.libraryNavSpacer {
  flex-grow: 1;
}

#ExistingEventModalContents {
  max-height: 60vh;
  overflow: auto;
}

div.EventControls {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

div.EventControlsSpacer {
  flex-grow: 1;
}

.EventOccurence.noselect:hover {
  cursor: pointer;
  color: blue;
}

.eventAssignedSegment {
  overflow-x: hidden;
  word-break: break-word;
}

.eventAssignedSegment:hover {
  cursor: default;
}

div.BlockControls {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

div.BlockControlsSpacer {
  flex-grow: 1;
}

.BlockOccurence.noselect:hover {
  cursor: pointer;
  color: blue;
}

.blockAssignedSegment {
  overflow-x: hidden;
  word-break: break-word;
}

.blockAssignedSegment:hover {
  cursor: default;
}

div.ui.input.blockColorInput input[type="color"] {
  padding: 0;
  height: auto;
}

div.scheduleToggle {
  margin: 3px;
}

div.scheduleToggle > div.ui.checkbox {
  vertical-align: text-bottom;
}

div.scheduleGapSelector {
  margin: 3px;
}

.tabbedComponentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ui.segment.tabbedComponentPane {
  flex-grow: 1;
  padding: 1em 0 0 0;
  min-height: 0;
}

.icon.tabbedCloseButton {
  font-size: 0.8em !important;
  position: absolute;
  top: 5px;
  left: 5px;
}

.icons.tabbedMenuItemUnsaved {
  margin-left: 0.3em;
  margin-right: -0.5em;
}

.ui.tabular.menu .item.tabbedMenuItem {
  padding-left: 1.8em;
}

div#scheduleEditor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

div#schedulePanel,
div#scheduleFilePanel {
  width: 0;
  border: 1px solid grey;
}

div#schedulePanel {
  flex-grow: 3;
  overflow-x: auto;
}

div#scheduleFilePanel {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

div#scheduleLibraryContainer {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
}

.filePanelTab {
  flex-grow: 1;
  overflow-y: auto;
}

.frameEditorPanel:hover .Frame.selected {
  z-index: 9999 !important;
}

.FrameContents {
  border: solid 1px white;
  outline: solid 1px black;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.FrameContents.drag:hover {
  cursor: move;
}

.FrameContents.selected {
  border-color: #21ba45;
  outline-color: #21ba45;
}

.FrameImage {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.FrameLabel {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: bold;
  color: white;
  text-shadow:
    -1px -1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    1px 1px 0 #000;
}

.FrameContents.selected .FrameLabel {
  color: #21ba45;
}

.FrameStatusIcons {
  display: inline-block;
  text-shadow:
    -1px -1px 0 #000,
    -1px 1px 0 #000,
    1px -1px 0 #000,
    1px 1px 0 #000;
  padding: 3px 0 0 5px;
}

.FrameImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.FrameImageBack {
  display: none;
}

.frameEditorPanel {
  height: 100%;
  border: solid 1px black;
  background-color: black;
}

#ActionSafeBoundary,
#TitleSafeBoundary {
  position: absolute;
  color: white;
  pointer-events: none;
  visibility: hidden;
}

div.BoundaryBorder {
  position: absolute;
  z-index: 9999;
  background: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: solid 1px rgba(255, 255, 255, 0.4);
}

#ActionSafeBoundary > span,
#TitleSafeBoundary > span {
  position: absolute;
  left: 0.5em;
  bottom: 0;
  z-index: 0;
}

.frameEditorPanel:hover > #ActionSafeBoundary,
.frameEditorPanel:hover > #TitleSafeBoundary,
#ActionSafeBoundary.show,
#TitleSafeBoundary.show {
  visibility: visible;
}

#ActionSafeBoundary {
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
}

#TitleSafeBoundary {
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
}

.FrameEditor {
  margin: auto;
  position: relative;
  max-width: 90vh;
}

.ratio4_3 {
  padding-bottom: 75%;
}

.ratio16_9 {
  padding-bottom: 56.25%;
}

.ratio3_2 {
  padding-bottom: 66.66%;
}

.ratio8_5 {
  padding-bottom: 62.5%;
}

.FrameEditorContent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.FrameEditorContainer {
  padding: 1em;
  overflow: hidden;
}

.PlaylistViewIndicator {
  position: absolute;
  height: 100%;
  width: 7px;
  background-color: red;
  pointer-events: none;
}

.PlaylistViewIndicator.preview {
  background-color: lime;
  display: none;
}

#PlaylistTimelineContents:hover .PlaylistViewIndicator.preview {
  display: initial;
}

.PlaylistViewIndicatorLine {
  position: absolute;
  left: calc(48% - 1px);
  top: 100%;
  width: 3px;
  border-left: 1px red solid;
  border-right: 1px red solid;
  height: 100vh;
  background-color: inherit;
  z-index: 1000;
}

.preview .PlaylistViewIndicatorLine {
  border-left-color: lime;
  border-right-color: lime;
  background-color: black;
}

.timeMarker {
  height: 50%;
  position: absolute;
}

.timeMarkerLabel {
  position: relative;
  right: 50%;
  top: 60%;
}

.timeMarkerBar {
  position: absolute;
  top: 0;
  width: 100%;
}

#PlaylistTimeBar {
  flex-grow: 2;
  background-color: lightgrey;
  position: relative;
}

#PlaylistTimeBar:hover {
  cursor: pointer;
}

#PlaylistTimeIndicators {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}

#PlaylistTimeIndicatorsPositioner {
  position: absolute;
  height: 100%;
  top: 0;
}

#PlaylistTimeHeader {
  width: 11em;
  background-color: darkgrey;
  border: 1px solid black;
  flex-shrink: 0;
  text-align: center;
}

#PlaylistTimeHeaderDisplay {
  vertical-align: middle;
  height: 100%;
  padding: 0;
}

div.PlaylistRegionName {
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

div.ui.PlaylistRegionName input {
  padding: 1px;
}

button#PlaylistAddRegionButton {
  height: 100%;
  border-radius: 0;
}

div.PlaylistRegionRow.selected div.PlaylistRegion.Sidebar {
  background-color: #cfc;
}

.PlaylistFrameClipImages {
  height: 100%;
  z-index: 0;
}

.ClipImageSpacer {
  display: inline-block;
  height: 100%;
}

.ClipImageContainer {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  position: absolute;
}

.ClipImage {
  max-height: 100%;
}

.PlaylistFrameClip {
  height: 100%;
  background-color: darkgrey;
  border: 1px solid black;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.PlaylistFrameClip:hover {
  background-color: lightgrey;
}

.PlaylistFrameClip.touchStart {
  border-left-style: dashed;
}

.PlaylistFrameClip.touchEnd {
  border-right-style: dashed;
}

.PlaylistFrameClip.selected {
  border: 2px solid green;
  background-color: #ccc;
}

.PlaylistFrameClipLabel {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  padding: 0 3px 0 3px;
  background-color: rgba(170, 170, 170, 0.5);
  font-weight: bold;
  color: white;
  text-shadow: -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 1px 1px 0 #000;
}

div.PlaylistFrameClipContainer {
  height: 100%;
}

.PlaylistFrameClipPreview {
  height: 100%;
  background-color: green;
  border: 1px solid black;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.7;
  pointer-events: none;
}

.PlaylistFrameClipPreview.colliding {
  background-color: red;
}

.PlaylistFrameClipPreview.unknownDuration {
  background-color: yellow;
}

.PlaylistFrameClipPreview.checkHover {
  display: none;
}

.PlaylistRegion.Timeline:hover .PlaylistFrameClipPreview.checkHover {
  display: inline-block;
}

.PlaylistRegionRow {
  max-height: 8em;
  height: 20%;
  white-space: nowrap;
  display: flex;
  min-height: 4em;
}

.PlaylistRegionRow.selected {
  border: 1px #21ba45 solid;
}

.PlaylistRegion {
  border: solid black 1px;
  border-top: 0px;
  height: 100%;
  width: 11em;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.PlaylistRegion.Timeline {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
}

#PlaylistHorizontalScrollbarContainer {
  width: 100%;
  display: flex;
}

#PlaylistHorizontalScrollbarPad {
  min-width: 11em;
  flex-shrink: 0;
}

#PlaylistHorizontalScrollbar {
  overflow: auto;
  width: 0;
  flex-grow: 1;
}

#PlaylistHorizontalScrollbarInterior {
  height: 1px;
}

#PlaylistToolbar.ui.menu .item.active {
  background-color: #21ba45;
  color: white;
}

.SelectionBox {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 128, 212, 0.5);
}

#PlaylistTimeline {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 1em;
  height: 0;
}

#PlaylistTimelineContents {
  display: flex;
  flex-direction:column;
  overflow: hidden;
}

#PlaylistHeader {
  height: 2em;
  display: flex;
  flex-shrink: 0;
}

#PlaylistBody {
  flex-grow: 1;
  min-height: 5em;
  display: flex;
  overflow: hidden;
}

#PlaylistRegionContainer {
  flex-grow: 1;
  width: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#PlaylistEditorPane {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

#PlaylistFrameContent {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

#OtherPlaylistContent {
  width: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

#CastusCutsMenu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#CutsControlsTab,
#CutsLibraryTab {
  flex-grow: 1;
}

#PlaylistTimelineContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

#PlaylistLibraryContainer {
  flex-grow: 1;
  position: relative;
}

#PlaylistControls {
  padding: 1em;
  flex-grow: 1;
}

#PlaylistControls > * {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

#PlaylistControls > .ui.menu.labeled {
  padding: 0;
}

div#libraryApplicationPane {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

div#libraryFileList {
  height: 0;
  flex-grow: 1;
}

/*
 * The button navbar is ugly when it's not flexing across the screen- but now it's a youtube rapper so I'm not sure if this is a good change.
 */
#library-navbar {
  display: flex;
}
.ui.menu#library-navbar .item.fitted::before {
  display: none;
}

#library-navbar .ui.button {
  margin: 3px;
}

#SettingsHeader {
  margin: 0.5em;
}

#ServicesList {
  padding: 0.5em;
}

.ui.icon.menu .item.serviceDeleteButton {
  color: #fff;
  background-color: #db2828;
}

#ServicePage {
  display: flex;
  flex-direction: column;
  height: 100%
}

#ServicesList {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.SettingsTabContents {
  overflow: auto;
  height: 100%;
}

#SettingsDiagnostics {
  margin-left: 0.5em;
  margin-top: 0.5em;
}

#createInputDropdownButton {
  float: right;
  margin-right: 0;
  margin-left: 0.25em;
}

div#LogsTabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.logsSection {
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

div.logsTextContainer {
  height: 0;
  flex-grow: 1;
  overflow: auto;
  white-space: nowrap;
}

div.ui.segment.logsText {
  display: inline-block;
  min-width: 100%;
}

#CopySettingsText,
#PasteSettingsText {
  height: 60vh;
}

#AccessUrlsText {
  width: 100%;
  height: 60vh;
}

tr.ndiModalSourceRow:hover {
  cursor: pointer;
}

.ui.label.SettingCheckboxLabel {
  margin-right: 1em;
}

.ui.toggle.checkbox {
  vertical-align: text-top;
}

.Setting.Description {
  padding: 0.5em 0 0 0.5em;
  font-weight: normal;
  font-size: 14px;
  line-height: 1em;
}

.SettingsDropdown {
  display: flex;
}

.SettingsDropdown > .ui.selection.dropdown {
  flex-grow: 1;
  width: 0;
}

.ui.accordion .title.SettingsCategoryHeader:not(.ui) {
  font-size: 1.3em;
  line-height: 1.3em;
  font-weight: 700;
}

#ServiceSettingsHeader {
  margin: 0.4em;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.ui.menu .item.settingsMenuDropdown::before {
  display: none;
}

#ServiceSettingsHeader .ui.header>.icon {
  font-size: 30px;
  margin-bottom: 5px;
}

#IconOffOrON {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  padding-right: 20px;
  padding-top: 7px;
}

#QueuePage {
  padding: 0.5em;
}

#RecordingControls {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#RecordingControlsTable {
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
  margin: 0.5em;
}

#RecordingControlsHeader {
  margin: 0.5em;
}

#RecordingControlsHeader {
  margin: 0.5em;
}

.inputRecording {
    display: flex;
    flex-direction: column;
}

.inputRecording__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.inputRecording__MainContainer {
    padding:10px;
}

.inputRecording__breadcrumb a {
    font-size: 11px;
}
.inputRecording__breadcrumb .active {
    font-size: 11px;
}
.inputRecording__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}
.inputRecording--icon {
    font-size:11px !important;
    margin-right: 10px !important;
}
.inputRecording--icon:last-child {
    margin-right: 0 !important;
}
.inputRecording__table {
    margin-top: 20px;
}
.inputRecording__Row {
    display:flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.inputRecording--div {
    width: 49%;
    min-height: 400px;
    position: relative;
}

.inputRecording--buttonMenu {
    margin-bottom: 8px;
}

.inputRecording--translationsMenu {
  display: flex;
}

.inputRecording--buttonMargin5 {
    margin-top: 8px;
}

.inputRecording--buttonMargin {
    margin-top: 100px;
}

.inputRecording__listArea {
    margin-top:30px;
}

.blackArea {
    background-color: #333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 5px transparent;
}

.blackArea.waiting {
    border: solid 5px orange;
}

.blackArea.recording {
    border: solid 5px red;
}

.ui.image#recordingPreview {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ui.page.modals.transition.visible {
    display: flex !important;
}

.ui.label.liveLabel {
  margin-left: 0.5em;
}

div#InputLiveControls {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ui.padded.table div.recordingEventTimeEdit td,
.ui.padded.table div.recordingEventTimeEdit th {
  padding: 0;
}

.recordingEventTimeEditConfirmLine {
  display: flex;
}

.ui.table tbody tr td.selectable:hover {
  cursor: pointer;
}

#RecordingControlsHeader {
  margin: 0.5em;
}

.inputRecording {
    display: flex;
    flex-direction: column;
}

.inputRecording__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.inputRecording__MainContainer {
    padding:10px;
}

.inputRecording__breadcrumb a {
    font-size: 11px;
}
.inputRecording__breadcrumb .active {
    font-size: 11px;
}
.inputRecording__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}
.inputRecording--icon {
    font-size:11px !important;
    margin-right: 10px !important;
}
.inputRecording--icon:last-child {
    margin-right: 0 !important;
}
.inputRecording__table {
    margin-top: 20px;
}
.inputRecording__Row {
    display:flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.inputRecording--div {
    width: 49%;
    min-height: 400px;
    position: relative;
}

.inputRecording--buttonMenu {
    margin-bottom: 8px;
}

.inputRecording--buttonMargin5 {
    margin-top: 8px;
}

.inputRecording--buttonMargin {
    margin-top: 100px;
}

.inputRecording__listArea {
    margin-top:30px;
}

.blackArea {
    background-color: #333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 5px transparent;
}

.blackArea.waiting {
    border: solid 5px orange;
}

.blackArea.recording {
    border: solid 5px red;
}

.ui.image#recordingPreview {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ui.page.modals.transition.visible {
    display: flex !important;
}

#AboutApplication {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#AboutTabs {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ui.active.tab.AboutTabPane {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  font-size: 12px;
  line-height: 14.5px;
}

#AboutChangelog {
  width: 100%;
}

#AboutHeader,
#AboutVersion {
  font-family: Arial,Helvetica,sans-serif;
  font-weight: bold;
  padding: 8px;
}

#AboutCopyright {
  padding: 8px;
  font-size: 10px;
  line-height: 12.5px;
}

#AboutHeader {
  font-size: 54px;
  line-height: 54px;
  padding-bottom: 4px;
  font-feature-settings: "smcp";
  font-variant: small-caps;
}

#AboutVersion {
  font-size: 36px;
  line-height: 36px;
  padding-top: 4px;
}


.about_listbox_table_versions tr td {
	border-bottom: 1px solid rgba(34,36,38,.15);
	border-radius: 0;
	padding: .78571429em;
}
.about_listbox_table_versions tr:last-child td {
	border-bottom: 0;
	border-radius: 0;
	padding: .78571429em;
}

.about_listbox_table_versions {
	width: 100%;
	margin: 0;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	box-sizing: border-box;
	color: rgba(0,0,0,.87);
	border-collapse: initial;
	border-spacing: 0;
	text-align: left;
	font: Lato,Arial,Helvetica,sans-serif;
	cursor: text;
	font-weight: 300;
	font-size: 1em;
}


.RegionContents {
  display: flex;
  flex-wrap: wrap;
}

.RegionLabel,
.RegionItem {
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.RegionControls {
  display: inline-block;
  flex-basis: 0;
  flex-grow: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.RegionControls,
.RegionItem {
  justify-content: center;
}

.RegionDeleteButton {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.RegionName {
  vertical-align: text-bottom;
  padding-left: 0.5em;
}

#GlobalMediaControls {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#GlobalMediaControlButtons {
  display: flex;
  align-items: center;
}

.spacer {
  flex-grow: 1;
}

#GlobalMediaRegionContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}

#GlobalMediaEditor {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#GlobalMediaEditor > .ui.header {
  margin: 0.5em;
}

#GlobalMediaManager {
  flex-grow: 1;
  display: flex;
}

#GlobalMediaLibraryContainer,
#GlobalMediaControlsContainer {
  flex-grow: 1;
  flex-basis: 0;
}

#GlobalMediaControlsContainer {
  margin-left: 1em;
}

.globalMusicContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.globalMusicContent__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
}
.globalMusicContent__buttons {
    margin-bottom:20px;
}
.globalMusicContent__details {
    margin-bottom:20px;
    font-size: 20px;
    line-height: 40px;
}
.globalMusicContent__footer {
    margin-top:20px;
}
.globalMusicContent--button {
    margin-right: 20px !important;
}
.libraryComponent {
    width: 100% !important;
    height: 100%;
}

.textGrid--row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;

}

.textGrid--m0 {
    margin: 0px !important;
}
.textGrid--Buttons {
    padding-top: 20px !important;
} 

.textGrid--w100 {
    width: 100%;
}
.fields {
    margin-bottom:0px !important;
}
.textGrid--segments {
    border: 0px !important;
    margin: 0px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.textGrid--segment-right {
    border: 0px !important;
    padding: 0px !important;
    box-shadow: 0px !important;
    -webkit-box-shadow: 0px !important;
    width: 65% !important;
    position: relative;
}
.textGrid--segment-left {
    font-size: 15px !important;
    font-weight: 400;
    border: 0px !important;
    padding: 10px 0px 0px 0px !important;
    box-shadow: 0px !important;
    -webkit-box-shadow: 0px !important;    
    width: 35% !important;
}

.scrollinText--input {
    width: 100% !important;
}
.scrollinText--dropdown {
    position: absolute !important;
    margin-top: 0px !important;
    right:0px !important;
    min-width: 10em !important;
    max-width: 12em !important;
}

.scrollingTextPreview {
    margin-top:15px;
    background-image: url("/checker8.png");
    border: 1px solid #cccccc;
    height: 100px;
    width: 100% !important;
    overflow: hidden;
    position: absolute;
}

.scrollingTextPreview__test {
  font-size: 40px;
  position: absolute;
  opacity: 0;
}

.scrollingTextPreview__marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    height:50px;
    padding-top: 15px;
}

.scrollingTextPreview__marquee span {
    display: inline-block;
    position: relative;
    font-size: 40px;
    animation: scrollingTextPreview__marquee 20s linear infinite;
}
/* Make it move */
@keyframes scrollingTextPreview__marquee {
    0%   { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

.scrollingTextPreview__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scrollingText {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.scrollingText__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.scrollingText__MainContainer {
    padding:10px;
    flex-grow: 1;
    height: 100%;
}

.scrollingText__breadcrumb a {
    font-size: 11px;
}
.scrollingText__breadcrumb .active {
    font-size: 11px;
}
.scrollingText__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}

.scrollingText__Row {
    display:flex; 
    flex-direction:row;
    height: 100%;
}

.scrollingText--div {
    width: 50%;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.scrollingText__Row textarea {
    border: 1px solid #CCC;
    padding: 10px;
    width: 95%;
    height: 85%;
    resize: none;
}
.scrollingText--previewDiv {
    position: relative;
    width: 95%;
}
.scrollingText__buttonGroup {
    margin-top: 10px !important; 
    padding-top: 10px !important; 
    border-top: 1px solid #cccccc;
}
.scrollingText__buttonGroup button{
    margin-right: 10px !important;
}
.scrollingText__errorText {
    border: 1px solid #990000;
    background: #fadbdb;
    padding: 6px;
    margin: 10px 0px 20px 0px;
}

.textGridContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}

@media screen and (max-width:1024px) {
    .scrollingText__Row { flex-direction: column;  }
    .scrollingText--div {
        width: 100%;
        position: inherit;
        margin-bottom: 20px;
        flex-basis: 0;
        flex-grow: 1;
    }
    .scrollingText--textarea { height: 400px; }
    .scrollingText--previewDiv { width: 100%; }
    .scrollingText__Row textarea {
        height: 300px;
        width: 100%;
    }
}

.rssItemEditor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rssItemEditor__breadcrumb {
    background-color: #e0e1e2;
    padding: 4px 6px 9px 9px;
    margin-bottom: 10px;
    height: 30px !important;
}

.rssItemEditor__MainContainer {
    padding:10px;
    flex-grow: 1;
    height: 100%;
}

.rssItemEditor__breadcrumb a {
    font-size: 11px;
}
.rssItemEditor__breadcrumb .active {
    font-size: 11px;
}
.rssItemEditor__title {
    margin-top:10px;
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px solid #cccccc;
    color: #333333;
}

.rssItemEditor__Row {
    display:flex; 
    flex-direction: row;
    height: 100%;
}

.rssItemEditor--div {
    width: 50% !important;
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.rssItemEditor--textarea {
    resize: none;
}

.rssItemEditor--url {
    margin-bottom: 15px;
    width: 95%;
}

.rssItemEditor__Row textarea {
    border: 1px solid #CCC;
    padding: 10px;
    width: 95%;
    height: 68%;
    resize: none;
}
.rssItemEditor__Row button {
    white-space: nowrap !important;
}
.rssItemEditor--previewDiv {
    position: relative;
    width: 95%;
}
.rssItemEditor__buttonGroup {
    margin-top: 10px !important; 
    padding-top: 10px !important; 
    border-top: 1px solid #cccccc;
}
.rssItemEditor__buttonGroup button{
    margin-right: 10px !important;
}
.rssItemEditor__errorText {
    border: 1px solid #990000;
    background: #fadbdb;
    padding: 6px;
    margin: 10px 0px 20px 0px;
}

.textGridContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
}

@media screen and (max-width:1024px) {
    .rssItemEditor__Row { flex-direction: column;  }
    .rssItemEditor--div {
        width: 100%;
        position: inherit;
        margin-bottom: 20px;
        flex-basis: 0;
        flex-grow: 1;
    }
    .rssItemEditor--textarea { height: 400px; }
    .rssItemEditor--previewDiv { width: 100%; }
    .rssItemEditor__Row textarea {
        height: 300px;
        width: 100%;
    }
    .rssItemEditor--url {
        width: 100%;
    }
}

div.metadataName {
  font-size: 1.1em;
  font-weight: bold;
}

div.metadataDescription {
  font-size: small;
  overflow-x: hidden;
  white-space: normal;
}

td.metadataColumn {
  overflow: hidden;
}

div#metadataEditorPane {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

div#metadataTablePane {
  height: 0;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#metadataHeader {
  margin: 0.5em;
}

#metadataFileDisplay {
  padding-left: 0.5em;
}

#metadataButtonPane {
  padding-left: 0.5em;
}

.ui.menu .item.menuDropdown::before {
  display: none;
}

.SimpleListItem:hover {
  background-color: #eee;
}

#SimpleListEditor {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
}

#SimpleListGrid,
#SimpleListGrid > .column,
#SimpleListPanel {
  max-height: 100%;
  height: 100%;
}

#SimpleListPanel {
  display: flex;
  flex-direction: column;
}

#SimpleListItems {
  flex-grow: 1;
  overflow-y: auto;
}

.ui.menu.item a.item.randomOn {
  background-color: #21ba45;
  color: white;
}

#SimpleListSaveDropdown .item::before {
  display: none;
}

#reportingAppContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

#reportingApp {
  flex-grow: 1;
}

#CloudServicesApp {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#CloudServicesTabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#LocalConfigPane,
#CloudPortalPane {
  height: 100%;
}

#CloudPortalPane {
  display: flex;
  align-items: center;
  justify-content: center;
}

#CloudAdminFrame {
  width: 100%;
}

.ui.menu .dropdown.item .menu#menuDropdown {
  display: none;
}

.ui.menu .dropdown.item .menu.visible#menuDropdown {
  position: absolute;
  display: flex!important;
  flex-direction: column!important;
  flex-wrap: wrap;
  max-height: 75vh;
  align-items: flex-start;
}

.ui.menu a.item.panic {
  background-color: red;
  animation: 0.3s infinite alternate panic;
}

@keyframes panic {
  from {
    background-color: #f00;
  }

  to {
    background-color: #700;
  }

}

#taskbar {
  background-image: linear-gradient(to right, rgb(48, 93, 170), rgb(75, 182, 73));
}

div#applications_menu > div.text {
  font-size: x-large;
  line-height: 1.8;
}

#taskbar:first-child,
#taskbar:last-child {
  border-radius: 0;
}

.ui.labeled.icon.menu .item > .pin-icon.icon:not(.dropdown),
.ui.labeled.icon.menu .item > .remove-icon.icon:not(.dropdown) {
  font-size: 0.8em !important;
  position: absolute;
  top: 5px;
}

.ui.labeled.icon.menu .item > .pin-icon.icon:not(.dropdown) {
  right: 5px;
  color: rgba(0,0,0,0.4);
}

.ui.labeled.icon.menu .item > .remove-icon.icon:not(.dropdown) {
  left: 5px;
  color: #fff;
}

.ui.label.tabNumbers {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 500rem 0 0 0;
}

.ui.image.menu-icon-svg {
  max-width: 100%;
  max-height: 100%;
}

/*
 * The date and time components had not ONE, BUT /TWO/ LINES! UNACCEPTABLE!! There is only one now.
 */
.ui.blue.small.label.ui.item.date {
  margin: 0;
}

.ui.blue.small.label.ui.item.zone {
  margin: 0;
}

.ui.labeled.icon.menu .item > .pin-icon.pinned.icon:not(.dropdown) {
  color: #fff;
}

#clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0.5em;
}

#timeClock {
  font-weight: bold;
  font-size: 16px;
}

#menu-icons {
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}

#TimeChangeSubmit {
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

#TimeChangeDateTime {
  min-height: 15em;
  display: flex;
  justify-content: center;
}

.item.statusLine {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

a.monitorLink {
  color: inherit;
}

div.audioMetersContainer {
  margin-top: 2px;
}

div.audioMeter {
  height: 0.5em;
  border: solid black 1px;
}

div.audioMeterBar {
  background-image: linear-gradient(to right, blue, green 33%, yellow 64%, red 100%);
  height: 100%;
}

span.sidebarSegmentControls {
  white-space: nowrap;
}

.ui.input.setSIMDaysInput {
  margin-top: 1em;
}

.ui.container.createNewSetForm.hidden {
  display: none;
}

#sidebar-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div.ui.sidebar-tab-pane {
  height: 0;
  flex-grow: 1;
  padding: 0;
}

#channel-sidebar-contents,
#output-sidebar-contents,
#input-sidebar-contents {
  border-radius: 0;
  height: 100%;
  overflow-y: auto;
  border: 0;
  display: block;
  background-color: #565656;
}

.ui.image.monitorImage {
  display: none;
}

.monitorPlaying {
  overflow:hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.ui.segment.widgetBarHamburger {
  padding: 0;
}

.ui.segment.widgetBarHamburger > .ui.fluid.dropdown {
  text-align: center;
}

.ui.card.SplashTile {
  box-shadow: none;
  font-size: 1.3em;
}

.ui.card.SplashTile > .content {
  border-top: none;
}

.ui.card.SplashTile.disabled {
  background-color: #e8e8e8;
}

.ui.card.SplashTile.disabled > .content > .header {
  color: grey;
}


#Home {
  padding: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}

#Home > div.ui.segment {
  overflow-x: hidden;
}

#Home .ui.button.basic {
  box-shadow: none;
}

#Home .ui.button.basic:hover {
  background-color: #cacbcd !important;
}

.SplashButton {
  width: 13.4vw;
  height: 13.4vw;
  display: flex;
  flex-direction: column;
  max-width: 196px;
  max-height: 196px;
}

.ui.card .image.SplashIcon {
  height: 15vw;
  max-height: 20vh;
  padding: 0.5em;
  background: none;
}

#HomeBetaLabel {
  text-align: center;
}

#create {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#create_menu {
  display: inline-block;
}

div#content {
  flex-grow: 1;
  background-color: white;
  overflow-y: auto;
}

.directoryModalLibrary {
  height: 50vh;
}

.ui.list.replicateModalList {
  height: 40vh;
  overflow-y: auto;
}

div#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
}

div#content_container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 0;
}

button#sidebar_visibility_toggle {
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

div.sidebar_container {
  display: flex;
  flex-direction: row;
  height: 0;
  flex-grow: 1;
}

div.sidebar {
  display: inline-block;
  width: 300px;
  min-width: 300px;
}

div.sidebar_in {
  visibility: hidden;
  width: 0px;
  min-width: 0px;
}

#BetaLabel {
  text-align: center;
  pointer-events: none;
}

div#authenticationPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#authenticationForm {
  margin: auto;
  padding: 25px;
  max-width: 450px;
  width: 90%;
  text-align: center;
  border: 1px grey solid;
}

div.authenticationInput {
  text-align: left;
}

div.authenticationInput > label {
  padding-left: 1em;
}

img#clientImage {
  max-width: 100%;
}

@media(max-width: 400px) {
  h1#authenticationHeader {
    font-size: 1.4rem;
  }
}

div#root, div#app_container {
  height: 100%;
}

.noselect {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.noselect:hover {
  cursor: default;
}

.icon.svg-icon {
  vertical-align: text-bottom;
}

i.icons.svg-icon-group .icon.svg-icon:first-child {
  width: 1.18em;
  vertical-align: text-bottom;
}

i.icons.svg-icon-group .icon.corner.caret.down {
  font-size: 0.8em;
  bottom: -0.8em;
  right: -0.1em;
}

.icon.svg-icon > .ui.image {
  max-width: 100%;
  max-height: 100%;
}

/* hand cursor */
.cursor-pointer {cursor: pointer;}

/* Fixes bug with modals being rendered in the upper-left instead of being centered */
.ui.page.transition.modals.active {
  display: flex !important;
}

.ui.modal {
  margin-top: 0px !important;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 3px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #787878;
  border-radius: 10px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #929292;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #787878;
  border-radius: 10px;
  border: 0px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #5f5f5f;
  border: 0px solid #010101;
  border-radius: 20px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #787878;
  border: 0px solid #010101;
  border-radius: 20px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  border: 0px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  height: 10px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #787878;
}
input[type=range]:focus::-ms-fill-upper {
  background: #929292;
}

