#AboutApplication {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#AboutTabs {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ui.active.tab.AboutTabPane {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  font-size: 12px;
  line-height: 14.5px;
}

#AboutChangelog {
  width: 100%;
}

#AboutHeader,
#AboutVersion {
  font-family: Arial,Helvetica,sans-serif;
  font-weight: bold;
  padding: 8px;
}

#AboutCopyright {
  padding: 8px;
  font-size: 10px;
  line-height: 12.5px;
}

#AboutHeader {
  font-size: 54px;
  line-height: 54px;
  padding-bottom: 4px;
  font-variant: small-caps;
}

#AboutVersion {
  font-size: 36px;
  line-height: 36px;
  padding-top: 4px;
}
