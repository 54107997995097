
.about_listbox_table_versions tr td {
	border-bottom: 1px solid rgba(34,36,38,.15);
	border-radius: 0;
	padding: .78571429em;
}
.about_listbox_table_versions tr:last-child td {
	border-bottom: 0;
	border-radius: 0;
	padding: .78571429em;
}

.about_listbox_table_versions {
	width: 100%;
	margin: 0;
	border: 1px solid rgba(34,36,38,.15);
	border-radius: .28571429rem;
	box-sizing: border-box;
	color: rgba(0,0,0,.87);
	border-collapse: initial;
	border-spacing: 0;
	text-align: left;
	font: Lato,Arial,Helvetica,sans-serif;
	cursor: text;
	font-weight: 300;
	font-size: 1em;
}

